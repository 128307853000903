export const sections = [
  {
    title: "Elements of Programming / JavaScript Basics",
    description: "variables, loops, if, functions, operators, etc",
    videos: [
      {
        videoId: "5UwyhjFY9r8",
        title: "Elementos de Programacion",
        date: "2020-08-16",
      },
      {
        videoId: "olhXzA5_7YE",
        title: "Tipos de Valores, exportacion de variables",
        date: "2020-08-29",
      },
      {
        videoId: "3AqE8qMz6bw",
        title: "Variables, Funciones",
        date: "2020-09-05",
      },
      {
        videoId: "5Ai-0LLFKic",
        title: "Objectos",
        date: "2020-09-14",
      },
      {
        videoId: "ytId9z-TD_M",
        title: "Bucles, Objetos",
        date: "2020-09-20",
      },
      {
        videoId: "cgFm3zFLfsI",
        title: "Arrays",
        date: "2020-09-23",
      },
      {
        videoId: "1s89LcCPMU8",
        title: "Introduccion a tarea de Arrays",
        date: "2020-09-27",
      },
      {
        videoId: "sVc9CoQQcac",
        title: "OH: Repaso de conceptos de JavaScript.",
        date: "2020-10-21",
      },
      {
        videoId: "pzCaIlnCBh4",
        title: "Etapas de conocimiento, explicacion de tareas de arrays",
        date: "2020-10-24",
      },
    ],
  },
  {
    title: "Basic Git and Github",
    description: "como colaborar en equipos",
    videos: [
      { videoId: "GyujOa_9j6E", title: "git and github", date: "2020-10-10" },
    ],
  },
  {
    title: "Editors and Tools ",
    description: "como usar las herramientas de javascript",
    videos: [
      {
        videoId: "8vuUEP8MtJ8",
        title: "Linebreaks & Prettier",
        date: "2020-10-03",
      },
      {
        videoId: "8vuUEP8MtJ8",
        title: "ESLint & Plugins de VSCode",
        date: "2020-09-30",
      },
    ],
  },
  {
    title: "Design",
    description:
      "Como disenar paginas y aplicaciones web, con herramientas como figma",
  },
  {
    title: "How to think",
    description: "estrategias para aprender, buscar, y resolver problemas",
  },
  {
    title: "Making a basic website",
    description: "como combinar HTML y JS, deploy con Netlify",
    videos: [
      {
        videoId: "5ay2rA2QmbI",
        title: "introduccion a paginas web",
        date: "2020-10-29",
      },
    ],
  },
  {
    title: "Basic CSS",
    description: "Como darle estilo a la pagina",
  },
  {
    title: "Fetching and Promises in Javascript",
    description: "Como usar informacion del internet en tu app",
  },
  {
    title: "React",
    description: "Como hacer aplicaciones web modernas",
  },
  {
    title: "Advanced Javascript",
    description: "Programacion funcional, recursion",
  },
  {
    title: "Redux",
    description: "Como manejar el estado de tu app",
  },
  {
    title: "Advanced git",
    description: "Rebase, Merge Conflicts, etc",
  },
  {
    title: "Algorithms",
    description: "Como hacer cosas mas complicadas",
  },
  {
    title: "Professional Education",
    description:
      "Como encontrar trabajo, como entrevistar, como conocer gente, imagen personal",
  },
];
