import React from "react";
import { Stage } from "./components/Stage";
import styled from "styled-components";
import { gray1 } from "./colors";
import { sections } from "./sections";

const AppContainer = styled.div`
  background-color: ${gray1};
`;

const Column = styled.div`
  background-color: ${gray1};
  max-width: 1200px;
  margin: auto;
  padding: 20px;
`;

const Header = styled.h1`
  font-family: "Fugaz One", cursive;
  margin: 0px;
  opacity: 0.7;
  margin-bottom: 30px;
`;

function App() {
  return (
    <AppContainer>
      <Column>
        <Header>Mendiola Developers</Header>
        {sections.map((stage, index, list) => (
          <Stage
            title={stage.title}
            description={stage.description}
            videos={stage.videos}
            sectionNumber={index + 1}
            isLast={index + 1 === list.length}
          />
        ))}
      </Column>
    </AppContainer>
  );
}

export default App;
