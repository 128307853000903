import React from "react";
import styled from "styled-components";
import { black1 } from "../../colors";
const infoColor = "white";

const Component = styled.a`
  background-color: ${infoColor};
  color: ${black1};
  padding: 10px;
  margin: 12px;
  border-radius: 5px;
  width: 200px;
  box-shadow: 1px 1px 8px 1px rgba(0, 0, 0, 0.15);
  text-decoration: none;
  transition: all .1s;
  &:hover {
    box-shadow: 1px 1px 20px 1px rgba(0, 0, 0, 0.2);
    transform: scale(1.3);
  }
`;

const Title = styled.div`
  font-size: 12px;
  font-weight: 600;
`;

const Date = styled.div`
  font-size: 12px;
  opacity: 0.7;
`;

const Image = styled.img`
  width: 200px;
`;

export const VideoThumbnail = ({ vId, date, title }) => {
  const thumbUrl = `http://img.youtube.com/vi/${vId}/0.jpg`;
  const videoUrl = `http://youtube.com/watch?v=${vId}`;

  return (
    <Component href={videoUrl} target="_blank">
      <Image src={thumbUrl} />
      <Date>{date}</Date>
      <Title>{title}</Title>
    </Component>
  );
};
