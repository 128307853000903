import React from "react";
import styled from "styled-components";
import { gray2, black1, black3 } from "../../colors";
import { VideoThumbnail } from "../VideoThumbnail";

const sectionColor = gray2;

const Component = styled.div``;

const Content = styled.div`
  display: flex;
  flex-direction: row;
`;

const LineContainer = styled.div`
  height: 100px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Line = styled.div`
  height: 100%;
  width: 1px;
  margin: 10px 0px;
  border-left: 3px dotted ${black3};
  opacity: 0.3;
`;

const MainBody = styled.div`
  flex: 1;
  background-color: ${sectionColor};
  padding: 10px;
  display: flex;
  flex-direction: row;
  border-radius: 5px;
  box-shadow: 1px 1px 11px 1px rgba(0, 0, 0, 0.05);
`;

const Title = styled.h2`
  font-size: 20px;
  margin: 0px;
  margin-bottom: 10px;
  padding: 0px;
  font-family: "Sansita Swashed", cursive;
`;

const Description = styled.p`
  margin: 0px;
  padding: 0px;
  font-family: "Open Sans", sans-serif;
`;

const SectionNumber = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  margin-right: 20px;
  font-size: 100px;
  font-family: "Fugaz One", cursive;
  opacity: 0.1;
`;

const LeftColumn = styled.div`
  color: ${black1};
  width: 250px;
  padding: 10px;
`;

const Divider = styled.div`
  border-right: 1px solid ${black3};
  margin: 10px;
  opacity: 0.2;
`;

const RightColumn = styled.div`
  padding: 10px;
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  flex-wrap: wrap;
`;

export const Stage = ({
  title,
  description,
  videos = [],
  sectionNumber = 1,
  isLast = false,
}) => {
  return (
    <Component>
      <Content>
        <SectionNumber>{sectionNumber}</SectionNumber>
        <MainBody>
          <LeftColumn>
            <Title>{title}</Title>
            <Description>{description}</Description>
          </LeftColumn>
          <Divider />
          <RightColumn>
            {videos.map((item) => (
              <VideoThumbnail
                vId={item.videoId}
                date={item.date}
                title={item.title}
              />
            ))}
          </RightColumn>
        </MainBody>
      </Content>
      {!isLast && (
        <LineContainer>
          <Line />
        </LineContainer>
      )}
    </Component>
  );
};
